import React, { Component } from "react"
import Modal from "react-modal/lib/components/Modal"
import { connect } from "react-redux"
import {
  changeDisplayedLanguage,
  getReturnOrderByDeliveryCode,
  logScreenEnterance,
  reportSuspiciousBrokenLockers,
  resetDeliveryReducer,
  restoreDeliveryCode,
  setShipingPackageOrder,
  reportFBEvent,
  clearShipingPackage,
  fetchAvailableLockersStatus,
} from "../../../../../actions"
import { DisEnbPendBtn, InputFiled, OrangeButton } from "../../../../common"
import VirtualKeyboard from "../../../../common/VirtualKeyboard"
import ErrorInfoModal from "../../../../Modals/ErrorInfoModal"
import { FixedFooter, FixedHeader } from "../../../../reduxified"
import TermsAndPolicyCb from "../../SignUpScreens/Step2Screen/TermsAndPolicyCb"
import "./style.scss"

class DeliverySmsLogin extends Component {
  constructor(props) {
    super(props)

    this.state = {
      deliveryCode: "",
      activeInput: null,
      codeRef: "deliveryCode",
      codeErr: false,
      isSubmitBtnDisabled: true,
      errorsTimeout: null,
      showErrorModal: false,
      errText: "",
      isPasswordResetModalShown: false,
      runValidationsTimeOut: null,
      openedDoorModalCloseClicked: false,
      serviceName: "",
      isCheckBoxChecked: false,
      showLegalInformationModal: false,
      largeModalTitle: "",
      largeModalContent: "",
    }
  }

  componentDidMount() {
    const { stationId } = this.props
    const from = this.props.history.location.state

    this.setState({ serviceName: this.props.history.location.state })
    if (
      stationId === null ||
      stationId < 0 ||
      stationId === undefined ||
      from === ""
    ) {
      this.props.reportFBEvent(
        "DeliverySmsLogin",
        "No stationId. Redirect to homepage."
      )
      this.props.history.push("")
    }

    this.props.logScreenEnterance("DeliverySmsLogin")
    this.props.resetDeliveryReducer()
    this.props.clearShipingPackage()
    this.props.fetchAvailableLockersStatus(stationId)
  }

  componentWillUnmount() {
    const { errorsTimeout, runValidationsTimeOut } = this.state

    if (errorsTimeout) {
      clearTimeout(errorsTimeout)
    }

    if (runValidationsTimeOut) {
      clearTimeout(runValidationsTimeOut)
    }
  }

  componentWillReceiveProps(newProps) {
    const {
      authenticated,
      errTitle,
      errText,
      showErrorModal,
      stationId,
      langName,
      history,
      displayedOrder,
    } = newProps

    const { serviceName } = this.state

    if (authenticated) {
      this.props.setShipingPackageOrder(displayedOrder)
      history.push("/ShipingPackageScreen/SelectLocker", serviceName)
    }

    //! TODOD- RESET THE ERR TITLE AND TEXT WHEN STARTING NEW REQ!
    //const { errTitle: oldErrTitle, errText: oldErrText} = this.props

    if (showErrorModal) {
      this.setState({ showErrorModal: true })
    }
  }

  onKeyboardTextChange(text) {
    const { errorsTimeout } = this.state

    if (errorsTimeout) {
      clearTimeout(errorsTimeout)
    }

    this.setState({
      deliveryCode: text,
      codeErr: false,
      errorsTimeout: null,
    })

    this.runValidationsWithTimoutDebounce()
  }

  onRefReady(refName, ref) {
    if (!this.state[refName]) this.setState({ [refName]: ref })
  }

  isCodeValid(deliveryCode, evt) {
    if (
      deliveryCode == undefined ||
      !deliveryCode ||
      deliveryCode.length === 0
    ) {
      return false
    }

    let isValidField = true
    if (evt !== undefined) {
      //isValidField = evt.target.validity.valid;
    }
    return isValidField && deliveryCode.length === 6
  }

  runValidations(evt) {
    const { deliveryCode, isCheckBoxChecked } = this.state
    const isCodeValid = this.isCodeValid(deliveryCode, evt)
    //const isEmptyPwd = pwd.length < 1;

    const isSubmitBtnDisabled = !isCodeValid || !isCheckBoxChecked
    const codeErr = !isCodeValid
    if (!isSubmitBtnDisabled) {
      this.setState({
        isSubmitBtnDisabled,
        codeErr,
        runValidationsTimeOut: null,
      })
    } else {
      const errorsTimeout = setTimeout(
        () =>
          this.setState({
            isSubmitBtnDisabled,
            codeErr,
            runValidationsTimeOut: null,
          }),
        800
      )

      this.setState({ errorsTimeout })
    }
  }

  onSignInClick() {
    const { deliveryCode } = this.state
    const { stationId } = this.props
    if (this.isCodeValid(deliveryCode)) {
      this.props.getReturnOrderByDeliveryCode(stationId, deliveryCode, 21, 18)
    }
  }

  onInputFieldChange(inputName, val, evt) {
    evt.persist()
    this.setState({ [inputName]: val })
    this.runValidationsWithTimoutDebounce(evt)
  }

  runValidationsWithTimoutDebounce(evt) {
    let { runValidationsTimeOut } = this.state

    if (runValidationsTimeOut) {
      clearTimeout(runValidationsTimeOut)
    }

    runValidationsTimeOut = setTimeout(() => this.runValidations(evt), 500)
    this.setState({ runValidationsTimeOut })
  }

  onCheckboxChange(newState) {
    let { isSubmitBtnDisabled } = this.state

    if (!newState) {
      isSubmitBtnDisabled = true
    }

    this.setState({
      isCheckBoxChecked: newState,
      isSubmitBtnDisabled,
    })

    this.runValidationsWithTimoutDebounce()
  }

  onTermsLinkClick() {
    this.setState({
      largeModalTitle: "תנאי שימוש",
      showLegalInformationModal: true,
    })
  }

  onPrivacyPolicyClick() {
    this.setState({
      largeModalTitle: "מדיניות הפרטיות",
      showLegalInformationModal: true,
    })
  }

  filterEnterAndSubmit(e) {
    if (e.key === "Enter") {
      setTimeout(() => {
        const { isSubmitBtnDisabled } = this.state

        if (!isSubmitBtnDisabled) {
          this.onSignInClick()
        }
      }, 700)
    }
  }

  onLangChange(lang) {
    this.props.changeDisplayedLanguage(lang)
  }
  onErrorClose() {
    this.setState({ showErrorModal: false, deliveryCode: "" })
  }

  render() {
    const {
      deliveryCode,
      isSubmitBtnDisabled,
      codeErr,
      showErrorModal,
      isCheckBoxChecked,
      largeModalTitle,
      showLegalInformationModal,
    } = this.state

    const {
      isLoadingRequest,
      dirRtl,
      sms_login_title,
      sms_login_subtitle,
      sms_login_input_placeholder,
      delivery_sms_login_button_text,
      close_text,
      sms_login_not_found_order_error_title,
      sms_login_not_found_order_error_text,
      termsCbTranslation,
      return_package,
    } = this.props

    return (
      <div className="ls-wrapper sms-login-screen">
        <FixedHeader
          title={sms_login_title}
          subTitle={sms_login_subtitle}
          buttonsFlag="showRestoreDeliveryCode"
        />

        <div className="content-wrapper" dir={dirRtl ? "rtl" : "ltr"}>
          <div className="enter-text">
            <h1>{return_package}</h1>
            <h3>{sms_login_subtitle}</h3>
          </div>
          <InputFiled
            value={deliveryCode}
            type="tel"
            pattern="[0-9]"
            maxLength={6}
            onChange={(e) =>
              this.onInputFieldChange("deliveryCode", e.target.value, e)
            }
            placeholder={sms_login_input_placeholder}
            onRefReady={(ref) => this.onRefReady("codeRef", ref)}
            onFocusChanged={(isFocused) =>
              this.onInputFocusChange("codeRef", isFocused)
            }
            displayErr={codeErr}
            onKeyPress={(e) => this.filterEnterAndSubmit(e)}
            smsCode
          />
          <TermsAndPolicyCb
            isCheckBoxChecked={isCheckBoxChecked}
            onCheckboxChange={(isCheck) => this.onCheckboxChange(isCheck)}
            onTermsClick={() => this.onTermsLinkClick()}
            onPrivacyClick={() => this.onPrivacyPolicyClick()}
            translation={termsCbTranslation}
          />
          <div className="op-login-btn-wrapper">
            <DisEnbPendBtn
              onClick={(e) => this.onSignInClick()}
              isDisabled={isSubmitBtnDisabled}
              isPending={isLoadingRequest}
            >
              {delivery_sms_login_button_text}
            </DisEnbPendBtn>
          </div>
          <VirtualKeyboard
            currentValue={this.state["deliveryCode"]}
            show
            onKeyboardInputChange={(txt) => this.onKeyboardTextChange(txt)}
            lang={"code"}
            maxLength={6}
            dhl
          />
        </div>
        <Modal
          isOpen={showLegalInformationModal}
          onRequestClose={() =>
            this.setState({
              showLegalInformationModal: false,
            })
          }
          className="react-modal-content big-react-modal"
          overlayClassName="modal-overlay"
        >
          <div className="lim-details-container">
            <p className="lim-label-extra-class">{largeModalTitle}</p>
            <iframe
              src="https://done.co.il/policy/"
              className="iframe_policy"
            ></iframe>
            <OrangeButton
              extraClass="lim-close-btn"
              onClick={() =>
                this.setState({
                  showLegalInformationModal: false,
                })
              }
            >
              חזרה
            </OrangeButton>
          </div>
        </Modal>

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showErrorModal}
          title={sms_login_not_found_order_error_title}
          text={sms_login_not_found_order_error_text}
          onModalCloseClick={() => this.onErrorClose()}
          closeTrnsl={close_text}
        />
        <FixedFooter history={this.props.history} />
      </div>
    )
  }
}

const mapStateToProps = ({
  bgService,
  translate,
  activeDelivery,
  userLogin,
}) => {
  const { stationId } = bgService
  const { authenticated, errTitle, errText } = userLogin
  const { isLoadingRequest, showErrorModal, displayedOrder } = activeDelivery
  const {
    dirRtl,
    langName,
    sms_login_title,
    sms_login_subtitle,
    sms_login_input_placeholder,
    delivery_sms_login_button_text,
    sms_login_didnot_get_sms,
    login_btn_text,
    password_for_delivery,
    close_text,
    back_btn_text,
    system_updgrade_err_msg,
    sms_login_not_found_order_error_title,
    sms_login_not_found_order_error_text,
    return_package,
    im_approving,
    terms_of_use,
    and_the_privacy_policy,
  } = translate

  const termsCbTranslation = {
    im_approving,
    terms_of_use,
    and_the_privacy_policy,
  }
  return {
    authenticated,
    errTitle: translate[errTitle],
    errText: translate[errText],
    isLoadingRequest,
    showErrorModal,
    stationId,
    termsCbTranslation,
    dirRtl,
    langName,
    sms_login_title,
    sms_login_subtitle,
    sms_login_input_placeholder,
    delivery_sms_login_button_text,
    sms_login_didnot_get_sms,
    login_btn_text,
    password_for_delivery,
    back_btn_text,
    close_text,
    sms_login_not_found_order_error_title,
    sms_login_not_found_order_error_text,
    system_updgrade_err_msg,
    return_package,
    displayedOrder,
  }
}

export default connect(mapStateToProps, {
  getReturnOrderByDeliveryCode,
  restoreDeliveryCode,
  resetDeliveryReducer,
  changeDisplayedLanguage,
  reportSuspiciousBrokenLockers,
  logScreenEnterance,
  setShipingPackageOrder,
  reportFBEvent,
  clearShipingPackage,
  fetchAvailableLockersStatus,
})(DeliverySmsLogin)
