export const findOrderByBarcodeAndRole = (
  barcodeText,
  readyOrdersList,
  role
) => {
  const barcodeTextLower = barcodeText.toString().toLowerCase()
  console.log("barcodeTextLower: " + barcodeTextLower)

  const matchList = readyOrdersList.filter((o) => {
    const isPackageNumber =
      o.packageNumber &&
      o.packageNumber.length > 0 &&
      o.packageNumber.toString().toLowerCase() === barcodeTextLower
    const isOrderNumber =
      o.orderNumber && o.orderNumber.toString() === barcodeText

    if (!o.externalOrderNumber && !o.externalOrderNumber2) {
      return isPackageNumber || isOrderNumber
    }

    let isExternalOrderNumber = false
    let isExternalOrderNumber2 = false

    if (
      role === "Manager" ||
      role === "DHL" ||
      role === "HFD" ||
      role === "ZigZag"
    ) {
      isExternalOrderNumber =
        o.externalOrderNumber &&
        o.externalOrderNumber.toString().toLowerCase() === barcodeTextLower
    }

    if (role === "Manager" || role === "HFD") {
      isExternalOrderNumber2 =
        o.externalOrderNumber2 &&
        o.externalOrderNumber2.toString().toLowerCase() === barcodeTextLower
    }

    return (
      isPackageNumber ||
      isOrderNumber ||
      isExternalOrderNumber ||
      isExternalOrderNumber2
    )
  })
  return matchList
}
