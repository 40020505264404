import React, { Component } from "react"
import { connect } from "react-redux"
import {
  logScreenEnterance,
  resetCoupons,
  setNewCreatedOrderItems,
  setNewCreatedOrder,
  LogoutUser,
} from "../../../../actions"
import { reportFBEvent } from "../../../../actions/FBEventsActions"
import { DisableEnableButton, OrangeButton } from "../../../common"
import AttachCouponModal from "../../../Modals/AttachCouponModal"
import OrderConfirmationModal from "../../../Modals/OrderConfirmationModal"
import ServiceDetailsUpdate from "../../../Modals/ServiceDetailsUpdate"
import YesNoInfoModal from "../../../Modals/YesNoInfoModal"
import WarningInfoModal from "../../../Modals/WarningInfoModal"
import CardCheckModal from "../../../Modals/CardCheckModal"
import CardCheckNAYAXModal from "../../../Modals/CardCheckNAYAXModal"
import { FixedFooter, FixedHeader } from "../../../reduxified"
import ItemsList from "./ItemsList"
import ItemsSquares from "./ItemsSquares"
import Modal from "react-modal"
import errorImg from "../../../../assets/svg/error-sign.svg"
import axios from "axios"
import api from "../../../../api/LocalServiceApi"
import azureApi from "../../../../api/AzureApi"

import "./style.css"

class NewOrderScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      itemToEdit: null,
      showModal: false,
      showOrderConfirmationModal: false,
      showAttachCouponModal: false,
      items: [],
      showYesNoModal: false,
      showCardCheckModal: false,
      showCardCheckNAYAXModal: false,
      showYesNoInfoModal: false,
      showChangesNotAllowed: false,
      isOldUser: false,
      coupon: {
        couponName: "",
        couponId: "",
        couponDescription: "",
        discount: 0,
        discountType: 0,
      },
      cardToken: "",
      invalidToken: false,
      orderType: 1,
      sessionCanceled: false,
    }
  }

  async componentDidMount() {
    const { authenticated, userId, stationPaymentVersion } = this.props
    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }
    this.props.logScreenEnterance("USER_NEW_ORDER_SCREEN")

    await axios
      .get(azureApi.isOldUser(userId))
      .then((res) => {
        this.setState({ isOldUser: res.data })
      })
      .catch((err) => console.error(err))

    if (!this.state.isOldUser && stationPaymentVersion !== 3) {
      this.setState({ showCardCheckModal: true })
    }
    if (!this.state.isOldUser && stationPaymentVersion === 3) {
      this.setState({ showCardCheckNAYAXModal: true })
    }

    const { orderForConfirmCreate } = this.props
    const { coupon } = this.state

    if (orderForConfirmCreate) {
      this.setState({
        items: orderForConfirmCreate.orderItems,
        coupon: { ...coupon, couponId: orderForConfirmCreate.couponId },
      })
    }
  }

  componentWillReceiveProps(newProps) {
    const { authenticated } = newProps

    if (!authenticated) {
      this.props.reportFBEvent(
        "NEW_ORDER_SCREEEN",
        "Not authenticated. Redirect to homepage."
      )
      this.props.LogoutUser()
      this.props.history.push("")
    }

    const { orderForConfirmCreate } = newProps
    if (this.props.orderForConfirmCreate !== orderForConfirmCreate) {
      if (!orderForConfirmCreate) {
        this.resetStateItems()
      } else {
        this.setOrderForConfirmCreateToState(orderForConfirmCreate)
      }
    }
  }

  setOrderForConfirmCreateToState(order) {
    const { coupon } = this.state

    this.setState({
      orderNumber: order.orderNumber,
      orderType: order.orderType,
      items: order.orderItems,
      coupon: { ...coupon, couponId: order.couponId },
    })
  }

  resetStateItems() {
    this.setState({
      orderNumber: null,
      orderType: 1,
      items: [],
      coupon: {
        couponName: "",
        couponId: "",
        couponDescription: "",
        discount: 0,
        discountType: 0,
      },
    })
  }
  onItemClick(icon, title) {
    if (this.props.orderForConfirmCreate) {
      this.setState({ showChangesNotAllowed: true })
      return
    }
    if (!icon) {
      return
    }
    const { items } = this.state
    const itemToEdit = items.filter((itm) => itm.title === title)[0] || {
      icon,
      title,
      amount: 0,
    }
    this.setState({ itemToEdit, showModal: true })
  }

  onModalFinished(updatedItem) {
    const { items } = this.state
    const filteredItems = items.filter((itm) => itm.title !== updatedItem.title)
    const finalItems = [updatedItem, ...filteredItems].filter(
      (itm) => itm.amount !== 0
    )
    if (finalItems.length > 9) {
      for (let i = 0; i < finalItems.length; i++) {
        if (i > 8 && !finalItems[i].title && !finalItems[i].amount) {
          finalItems.pop()
        }
      }
    }

    this.setState({
      items: finalItems,
      itemToEdit: null,
      showModal: false,
    })
  }

  onDeleteItemClick(itmToDel) {
    if (this.props.orderForConfirmCreate) {
      this.setState({ showChangesNotAllowed: true })
      return
    }
    const { items } = this.state
    const filteredItems = items.filter((itm) => itm.title !== itmToDel.title)
    const finalItems = filteredItems.filter((itm) => itm.amount !== 0)
    if (finalItems.length > 9) {
      for (let i = 0; i < finalItems.length; i++) {
        if (i > 8 && !finalItems[i].title && !finalItems[i].amount) {
          finalItems.pop()
        }
      }
    }
    this.setState({
      items: finalItems,
      itemToEdit: null,
      showModal: false,
    })
  }

  closeOrderConfirmationModal() {
    this.setState({ showOrderConfirmationModal: false })
  }

  closeAttachCouponModal() {
    this.props.resetCoupons()
    this.setState({ showAttachCouponModal: false })
  }

  onAddingCoupon(coupon) {
    if (!coupon || coupon.couponId === "") {
      this.closeAttachCouponModal()
      return
    }
    this.setState({
      ...this.state,
      coupon: {
        couponName: coupon.couponName,
        couponId: coupon.couponId,
        couponDescription: coupon.couponDescription,
        discount: coupon.discount,
        discountType: coupon.discountType,
      },
    })
    this.closeAttachCouponModal()
  }

  onOrderConfirmation() {
    const { items, coupon } = this.state

    const trueItems = items.filter((itm) => itm.amount !== "")
    this.props.setNewCreatedOrderItems(trueItems, coupon.couponId)
    const preOrder = this.props.history.location.state === "confirmOrder"

    preOrder
      ? this.props.history.push("OrderItemsDepositScreen")
      : this.props.history.push("OrderItemsDepositScreen", "laundry")
  }

  onOkClick() {
    this.setState({
      showOrderConfirmationModal: true,
    })
  }

  onAttachCouponClick() {
    this.setState({
      showAttachCouponModal: true,
    })
  }

  onCancelClick() {
    if (this.props.orderForConfirmCreate) {
      this.setState({ showChangesNotAllowed: true })
      return
    }
    this.setState({
      showYesNoModal: true,
    })
  }

  renderCouponDiscountText() {
    if (!this.state.coupon || this.state.coupon.couponId === "") {
      return ""
    }
    return (
      this.props.added_coupon_discount +
      " " +
      this.state.coupon.discount +
      (this.state.coupon.discountType === 0 ? "%" : "₪")
    )
  }
  renderCouponDiscountView() {
    if (!this.state.coupon || this.state.coupon.couponId === "") {
      return <></>
    }
    return (
      <div className="nos-frame-discount-wrapper">
        <div className="nos-frame-discount">
          <div className="nos-frame-discount-text">
            {this.props.added_coupon_discount}
          </div>
          <div className="nos-frame-discount-text">
            {this.state.coupon.discount +
              (this.state.coupon.discountType === 0 ? "%" : "₪")}
          </div>
        </div>
      </div>
    )
  }

  cancelPaymentNayax() {
    axios
      .get(api.cancelNayaxMultiSession)
      .then(() => {
        this.setState({ sessionCanceled: true })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  checkCreditCard(cCard, formattedDate) {
    axios
      .post(api.getPelecardToken, { cCard, formattedDate })
      .then((res) => {
        console.log("res.data.receivedToken", res.data.receivedToken)
        res.data.receivedToken
          ? this.setState({
              cardToken: res.data.receivedToken,
              showCardCheckModal: false,
              showYesNoInfoModal: false,
              showCardCheckNAYAXModal: false,
            })
          : this.setState({
              invalidToken: true,
              showCardCheckModal: false,
              showCardCheckNAYAXModal: false,
              showYesNoInfoModal: true,
            })
      })
      .catch((err) => {
        console.log(err)
        this.setState({
          invalidToken: true,
          showCardCheckModal: false,
          showCardCheckNAYAXModal: false,
          showYesNoInfoModal: true,
        })
      })
  }

  checkNAYAXCreditCard = () => {
    axios
      .get(api.validationNAYAX)
      .then((res) => {
        if (res.data.isApproved) {
          this.setState({ showCardCheckNAYAXModal: false })
        }

        if (!this.state.sessionCanceled && !res.data.isApproved) {
          this.setState({
            showCardCheckNAYAXModal: false,
            showYesNoInfoModal: true,
          })
        }
      })
      .catch(() => {
        !this.state.sessionCanceled &&
          this.setState({
            showCardCheckNAYAXModal: false,
          })
      })
  }

  render() {
    const {
      showModal,
      showCardCheckNAYAXModal,
      itemToEdit,
      items,
      orderNumber,
      orderType,
      showOrderConfirmationModal,
      showAttachCouponModal,
      showYesNoModal,
      showCardCheckModal,
      showYesNoInfoModal,
      showChangesNotAllowed,
    } = this.state

    const {
      dirRtl,
      desired_services,
      item,
      quantity,
      squaresTranslations,
      total_items,
      order_approve_btn_text,
      cancel_order_btn_text,
      add_coupon_btn_text,
      cancel_order_modal_title,
      cancel_order_modal_body,
      ok_text,
      cancel_text,
      orderConfirmTransl,
      translPackage,
      firstName,
      hello_title_text,
      new_order_screen_sub_title_text,
      card_not_detected,
      try_again,
      close_text,
      not_allowed_order_changes_title,
      not_allowed_order_changes_text,
      order_number,
    } = this.props

    return (
      <div className="ls-wrapper">
        <FixedHeader subTitle={desired_services} buttonsFlag="newOrderScreen" />
        <div
          className="nos-title new-order-screen"
          dir={dirRtl ? "rtl" : "ltr"}
        >{`${hello_title_text} ${firstName}`}</div>
        <div className="nos-subtitle">{new_order_screen_sub_title_text}</div>
        <div className="nos-frame-main-container" dir={dirRtl ? "rtl" : "ltr"}>
          <div className="nos-frame-container">
            <div className="nos-frame-inner-wrapper">
              <ItemsSquares
                onItemClick={this.onItemClick.bind(this)}
                squaresTranslations={squaresTranslations}
              />
              <ItemsList
                HeaderItems={["", item, quantity]}
                Items={items}
                onDeleteItemClick={this.onDeleteItemClick.bind(this)}
                onItemClick={({ icon, title }) => this.onItemClick(icon, title)}
                totalItmsTranslation={total_items}
                discountText={this.renderCouponDiscountText()}
                orderNumber={orderNumber}
                order_number={order_number}
                dirRtl={dirRtl}
              />
            </div>
            <div className="nos-frame-btns">
              <div className="two-buttons-div">
                <OrangeButton
                  extraClass="nos-frame-single-btn white-button"
                  onClick={() => this.onCancelClick()}
                >
                  {cancel_order_btn_text}
                </OrangeButton>

                <OrangeButton
                  extraClass="nos-frame-single-btn white-button"
                  onClick={() => this.onAttachCouponClick()}
                >
                  {add_coupon_btn_text}
                </OrangeButton>
              </div>
              <div className="one-button-div">
                <DisableEnableButton
                  extraClass="nos-frame-single-btn"
                  onClick={() => this.onOkClick()}
                  isDisabled={
                    items.filter((itm) => itm.title && itm.amount).length < 1
                  }
                >
                  {order_approve_btn_text}
                </DisableEnableButton>
              </div>
            </div>
          </div>
        </div>

        <FixedFooter history={this.props.history} />

        <ServiceDetailsUpdate
          show={showModal}
          item={itemToEdit}
          onModalFinished={this.onModalFinished.bind(this)}
          translPackage={translPackage}
        />

        <OrderConfirmationModal
          dirRtl={dirRtl}
          show={showOrderConfirmationModal}
          items={items}
          onOkClick={() => this.onOrderConfirmation()}
          onCancelClick={() => this.closeOrderConfirmationModal()}
          translations={orderConfirmTransl}
        />
        <AttachCouponModal
          dirRtl={dirRtl}
          show={showAttachCouponModal}
          onOkClick={(coupon) => this.onAddingCoupon(coupon)}
          onCancelClick={() => this.closeAttachCouponModal()}
          orderType={orderType}
        />
        <YesNoInfoModal
          dirRtl={dirRtl}
          show={showYesNoModal}
          title={cancel_order_modal_title}
          text={cancel_order_modal_body}
          okTransl={ok_text}
          cancelTransl={cancel_text}
          onModalOkClick={() => this.props.history.goBack()}
          onModalCancelClick={() => this.setState({ showYesNoModal: false })}
        />
        <CardCheckModal
          show={showCardCheckModal}
          onCloseModal={() => {
            this.setState({ showCardCheckModal: false })
            this.props.history.goBack()
          }}
          checkCreditCard={this.checkCreditCard.bind(this)}
        />
        {showCardCheckNAYAXModal && (
          <CardCheckNAYAXModal
            show={showCardCheckNAYAXModal}
            onCloseModal={() => {
              this.cancelPaymentNayax()
              this.props.history.goBack()
            }}
            cancelPaymentNayax={() => this.cancelPaymentNayax()}
            checkCreditCard={this.checkCreditCard.bind(this)}
            checkNAYAXCreditCard={this.checkNAYAXCreditCard.bind(this)}
          />
        )}

        <Modal
          isOpen={showYesNoInfoModal}
          className="react-modal-content"
          overlayClassName="modal-overlay"
        >
          <img src={errorImg} alt="_X_" />
          <div className="retry-text">{card_not_detected}</div>
          <div className="retry-buttons">
            <OrangeButton
              onClick={() => {
                const { stationPaymentVersion } = this.props
                stationPaymentVersion === 3
                  ? this.setState({
                      sessionCanceled: false,
                      showYesNoInfoModal: false,
                      showCardCheckNAYAXModal: true,
                    })
                  : this.setState({
                      showYesNoInfoModal: false,
                      showCardCheckModal: true,
                    })
              }}
            >
              {try_again}
            </OrangeButton>
            <OrangeButton onClick={() => this.props.history.goBack()}>
              {close_text}
            </OrangeButton>
          </div>
        </Modal>

        <WarningInfoModal
          show={showChangesNotAllowed}
          title={not_allowed_order_changes_title}
          text={not_allowed_order_changes_text}
          btnText={close_text}
          onModalCloseClick={() =>
            this.setState({ showChangesNotAllowed: false })
          }
        />
      </div>
    )
  }
}
const mapStateToProps = ({
  userLogin,
  lockersCells,
  bgService,
  translate,
  readyOrders,
  stationServiceTypes,
}) => {
  const { authenticated, userId, firstName } = userLogin
  const { stationId } = bgService
  const { stationPaymentVersion } = stationServiceTypes
  const { nextAllAvailableCells, brokenLockersNumArr } = lockersCells
  const { newCreatedOrder, orderForConfirmCreate } = readyOrders //TODO orderForConfirmCreate
  const {
    dirRtl,
    new_order_screen_sub_title_text,
    item_description,
    item_quantity,
    total_items,
    ironing_item_btn_text: ironing,
    laundry_item_btn_text: laundry,
    ironing_and_boxing_item_btn_text: ironing_and_package,
    dry_cleaning_btn_text: dry_cleaning,
    ironing_and_packing_item_btn_text: laundry_ironing_package,
    laundry_and_ironing_item_btn_text: laundry_ironing,
    order_approve_btn_text,
    cancel_order_btn_text,
    add_coupon_btn_text,
    added_coupon_discount,
    cancel_order_modal_title,
    cancel_order_modal_body,
    ok_text,
    cancel_text,
    back_btn_text,
    order_details_modal_title,
    order_details_modal_text,
    service_title,
    quantity_title,
    quantity_kg_title,
    comment_input_hint,
    hello_title_text,
    langName,
    card_not_detected,
    try_again,
    close_text,
    not_allowed_order_changes_title,
    not_allowed_order_changes_text,
    order_number,
  } = translate

  const lockerToOccupy =
    nextAllAvailableCells !== null ? nextAllAvailableCells[0] : null

  const squaresTranslations = {
    ironing,
    laundry,
    ironing_and_package,
    dry_cleaning,
    laundry_ironing_package,
    laundry_ironing,
  }

  const orderConfirmTransl = {
    order_details_modal_title,
    order_details_modal_text,
    amount: item_quantity,
    item: item_description,
    ok_text,
    back_btn_text,
  }

  const translPackage = {
    dirRtl,
    service_title,
    quantity_title,
    quantity_kg_title,
    comment_input_hint,
    ok_text,
    cancel_text,
    langName,
  }

  return {
    authenticated,
    userId,
    stationPaymentVersion,
    lockerToOccupy,
    stationId,
    back_btn_text,
    dirRtl,
    hello_title_text,
    new_order_screen_sub_title_text,
    total_items,
    item: item_description,
    quantity: item_quantity,
    squaresTranslations,
    order_approve_btn_text,
    cancel_order_btn_text,
    add_coupon_btn_text,
    added_coupon_discount,
    cancel_order_modal_title,
    cancel_order_modal_body,
    ok_text,
    cancel_text,
    orderConfirmTransl,
    translPackage,
    firstName,
    card_not_detected,
    try_again,
    close_text,
    newCreatedOrder,
    orderForConfirmCreate,
    not_allowed_order_changes_title,
    not_allowed_order_changes_text,
    order_number,
    brokenLockersNumArr,
  }
}
export default connect(mapStateToProps, {
  setNewCreatedOrderItems,
  setNewCreatedOrder,
  logScreenEnterance,
  resetCoupons,
  reportFBEvent,
  LogoutUser,
})(NewOrderScreen)
